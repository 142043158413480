<template>
  <v-container v-if="freeEvent" fluid>
    <transition-group appear name="fadeGroup">
      <v-img key="a" max-height="500" :src="freeEvent.imageSrc">
        <v-row style="height: 100%" align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card-text
              style="color: white"
              class="text-center display-2 font-weight-bold"
              :class="{ 'headline font-weight-bold': $vuetify.breakpoint.xs }"
              >{{ freeEvent.title }}
            </v-card-text>
            <v-divider class="white" light />
            <v-card-text
              style="color: white; white-space: pre-line"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              class="headline text-center"
              >{{ freeEvent.subtitle }}</v-card-text
            >
          </v-col>
        </v-row>
      </v-img>
      <v-row key="b" justify="space-around" align="center" class="flex-column">
        <v-col cols="12" md="10">
          <v-card color="#ECEFF1">
            <v-card-text
              style="white-space: pre-line"
              class="text-justify"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              >{{ freeEvent.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row key="c" justify="center">
        <v-col sm="12" md="6">
          <v-card>
            <v-card-text style="color: #FB8C00" class="headline text-center"
              >Регистрация</v-card-text
            >
            <v-form ref="form" v-model="valid" validation>
              <v-card-text>
                <v-text-field
                  v-model="firstName"
                  color="blue lighten-4"
                  prepend-icon="mdi-account-outline"
                  label="Имя"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
                <v-text-field
                  v-model="lastName"
                  color="blue lighten-4"
                  prepend-icon="mdi-account-outline"
                  label="Фамилия"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
                <v-text-field
                  v-model="email"
                  color="blue lighten-4"
                  prepend-icon="mdi-email"
                  label="E-mail"
                  class="purple-input"
                  type="mail"
                  counter
                  :rules="emailRules"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="blue lighten-2"
                  text
                  @click="saveEventParticipant"
                >
                  <i class="caption text--darken-1">
                    Зарегистрироваться
                  </i>
                </v-btn>
              </v-card-actions>
            </v-form>
            <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
              <v-btn text color="orange lighten-2" @click="overlay = false">
                Вы зарегистрированы на {{ freeEvent.title }}
              </v-btn>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </transition-group>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegisterFreeEvent',
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imageSrc:
        'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Fnejro-ind-900.jpg?alt=media&token=db0853ed-616f-49fd-bb76-f72f30e4993a',
      valid: false,
      firstName: '',
      lastName: '',
      email: '',
      overlay: false,
      absolute: true,
      opacity: 0.8,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
        v => v.length <= 30 || 'Email must not be more than 30 characters'
      ],
      userDataRules: [v => !!v || 'Введите данные', v => v.length <= 30 || 'Hе более 30 символов']
    }
  },
  computed: {
    ...mapState({
      loading: state => state.shared.loading,
      user: state => state.user.user,
      freeEvent: state => state.shared.freeEvent,
      eventParticipants: state => state.shared.eventParticipants
    })
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler() {
        if (this.user) {
          this.email = this.user.email || ''
          this.firstName = this.user.firstName || ''
          this.lastName = this.user.lastName || ''
        }
      }
    },
    eventParticipants: {
      deep: true,
      immediate: true,
      handler() {
        this.overlay = this.eventParticipants.some(elem => elem.email === this.email)
      }
    },
    email: {
      deep: true,
      immediate: true,
      handler() {
        this.overlay = this.eventParticipants.some(elem => elem.email === this.email)
      }
    }
  },
  created() {
    this.$store.dispatch('getEvent', { eventId: this.eventId })
    this.$store.dispatch('getEventParticipants', { eventId: this.eventId })
  },
  methods: {
    saveEventParticipant() {
      if (this.valid) {
        const userData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }
        this.$store.dispatch('saveEventParticipant', { ...userData, eventId: this.eventId })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
